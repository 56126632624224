/** @jsx jsx */
import type { MovieCollection } from "gatsby-theme-schedule-options-bar";
import { useScheduleContext } from "gatsby-theme-schedule-options-bar";
import { ClosureMessage } from "gatsby-theme-warning-message";
import orderBy from "lodash/orderBy";
import React, { Fragment, memo, useEffect, useState } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import { RangeKey } from "shared/helpers/ranges";
import useAllocineSchedule from "shared/hooks/useAllocineSchedule";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type {
  ShowtimesGridQuery,
  WidgetShowtimesDisplay,
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../__generated__/gatsby.types";
import Grid from "./Grid";
import { Movie, Theater } from "./types";

const ScheduleOptionsBar = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleOptionsBar,
  }
);

interface Props {
  data: ShowtimesGridQuery;
  periodFilter?: readonly (RangeKey | null)[] | null;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
  display?: WidgetShowtimesDisplay;
  includeDatePicker?: boolean;
  includeDateSlider?: boolean;
  includeComingSoonTab?: boolean;
  includeTheaterSelector?: boolean;
  selectedTheater: Theater | null;
}

const ShowtimesGridComponent: React.FC<Props> = ({
  data,
  periodFilter,
  groupType,
  groupBy,
  display,
  selectedTheater,
  includeDatePicker,
  includeDateSlider,
  includeComingSoonTab,
  includeTheaterSelector,
}) => {
  const { rangeSelection } = useScheduleContext();

  const pageContext = usePageContext();
  const [view, setView] = useState<MovieCollection>("NOW_PLAYING");
  const [hadTheater, setHadTheater] = useState(!!selectedTheater);

  // Use the explicit parameter or fallback on multi locations detection
  const resolvedIncludeTheaterSelector =
    typeof includeTheaterSelector === "boolean"
      ? includeTheaterSelector
      : !pageContext.isSingleLocation;

  const allMovies = data.allMovie.nodes;

  const [scheduleStart, scheduleEnd] = rangeSelection.range;

  const { schedule, loading } = useAllocineSchedule(
    selectedTheater?.id,
    selectedTheater?.timeZone || undefined,
    allMovies.map((movie) => movie.id),
    scheduleStart,
    scheduleEnd
  );

  useEffect(() => {
    // TODO: try to uniformize query result and default payload
    setHadTheater(!!selectedTheater);
  }, [selectedTheater]);

  const hasMoviesComingSoon = data.comingSoon.nodes.length > 0;

  let movies: Movie[] = [];
  const viewMovies = allMovies.filter((movie) =>
    movie.collections?.includes(view)
  );
  /**
   * When selected theater is defined
   */
  if (selectedTheater) {
    switch (view) {
      /**
       * Now playing collection
       * Only using movies available in the schedule
       */
      case "NOW_PLAYING": {
        const scheduledMovieIds = !hadTheater
          ? viewMovies.map((movie) => movie.id)
          : Object.keys(schedule);
        movies = allMovies.filter((movie) =>
          scheduledMovieIds.includes(movie.id)
        );
        break;
      }

      /**
       * Coming soon collection
       * Only using movies flagged as coming soon in the selected theater
       */
      case "COMING_SOON": {
        movies = data.comingSoon.nodes.filter(
          (movie) =>
            movie.theaters?.findIndex(
              (theater) => theater?.theater?.id === selectedTheater.id
            ) !== -1
        );
        break;
      }

      default:
        break;
    }
  } else {
    /**
     * When no selected theater
     * Filter all movies depending on the collection type
     */
    movies = viewMovies;
  }

  movies = orderBy(
    movies,
    ["release", "title"],
    view === "NOW_PLAYING" ? ["desc", "asc"] : "asc"
  );

  return (
    <Fragment>
      <div sx={{ marginBottom: 4 }}>
        <ScheduleOptionsBar
          hasMoviesComingSoon={hasMoviesComingSoon}
          periodFilter={periodFilter}
          view={view}
          setView={setView}
          includeTheaterSelector={resolvedIncludeTheaterSelector}
          includeComingSoonTab={includeComingSoonTab}
          includeDatePicker={includeDatePicker}
          includeDateSlider={includeDateSlider}
          theaterId={selectedTheater?.id || "0"}
          periodSelectorDisabled={!selectedTheater?.id}
        />
      </div>

      {!pageContext.isSingleLocation && (
        <ClosureMessage theaterId={selectedTheater?.id} />
      )}

      <Grid
        view={view}
        movies={movies}
        schedule={
          selectedTheater && view !== "COMING_SOON" ? schedule : undefined
        }
        theater={selectedTheater || undefined}
        groupType={groupType}
        groupBy={groupBy}
        display={display}
        loading={view === "NOW_PLAYING" ? loading : false}
      />
    </Fragment>
  );
};

export default memo(ShowtimesGridComponent);

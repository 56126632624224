import { Maybe } from "../../utils/ts";

export type ShowtimeTags = Maybe<Maybe<string>[]>;

type Options = {
  showtimeTags?: ShowtimeTags;
  movieLanguage?: Maybe<string>;
  theaterLanguage?: Maybe<string>;
};

const ORIGINAL_VERSION_TAG = "Localization.Version.Original";
const LOCALIZATION_TAG_PREFIX = "Localization.Language.";

export const getLocalizationLanguage = (
  showtimeTags?: Maybe<Maybe<string>[]>
): Maybe<string> => {
  const localizationTag = showtimeTags?.find((showtimeTag) =>
    showtimeTag?.includes(LOCALIZATION_TAG_PREFIX)
  );

  if (!localizationTag) {
    return null;
  }

  const [, localizationLanguage] = localizationTag.split(
    LOCALIZATION_TAG_PREFIX
  );

  return localizationLanguage.toUpperCase();
};

export const getVersion = (options: Options): "OV" | "DUBBED" | null => {
  const { showtimeTags, movieLanguage, theaterLanguage } = options;

  // Return early if we are missing the base information
  if (!showtimeTags || !movieLanguage || !theaterLanguage) {
    return null;
  }

  const localizationLanguage = getLocalizationLanguage(showtimeTags);
  const normalizedMovieLanguage = movieLanguage.toUpperCase();
  const normalizedTheaterLanguage = theaterLanguage.toUpperCase();

  // Showtime tags includes the "original version" tag.
  // No need to go further, the showtime is correctly flagged.
  if (showtimeTags.includes(ORIGINAL_VERSION_TAG)) {
    return "OV";
  }

  // Showtime tags do not contain any longuage tags
  // No need to compare against movie or theater languages
  if (!localizationLanguage) {
    return null;
  }

  // Showtime language is the same as the Movie original language but the Theater language is different.
  // We can conclude that the showtime is to be considered OV.
  // ie.
  // Showtime tagged Localization.Language.English for John Wick (English) at the CGR Blagnac (French).
  if (
    localizationLanguage === normalizedMovieLanguage &&
    localizationLanguage !== normalizedTheaterLanguage
  ) {
    return "OV";
  }

  // Showtime language is different from the Movie original language.
  // We can conclude that the showtime is to be considered DUBBED.
  // ie.
  // Showtime tagged Localization.Language.French for John Wick (English).
  if (localizationLanguage !== normalizedMovieLanguage) {
    return "DUBBED";
  }

  return null;
};

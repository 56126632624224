/** @jsx jsx */
import React, { memo } from "react";
import { formatDuration } from "shared/helpers/duration";
import useIntl from "shared/helpers/i18n/useIntl";
import { MovieSchedule } from "shared/hooks/useAllocineSchedule";
import durationMessages from "shared/i18n/duration.i18n";
import { jsx } from "theme-ui";

import type {
  WidgetShowtimesDisplay,
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../../../__generated__/gatsby.types";
import type { Movie, Theater } from "../../types";
import MovieCard from "./Card";
import Wrapper from "./Wrapper";

interface Props {
  theater?: Theater;
  movie?: Movie;
  schedule?: MovieSchedule | null;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
  display?: WidgetShowtimesDisplay;
}

const MovieScheduleComponent: React.FC<Props> = ({
  movie,
  theater,
  schedule,
  groupType,
  groupBy,
  display,
}) => {
  const { formatMessage } = useIntl();

  if (!movie) {
    return null;
  }

  const durationFormat = formatMessage(durationMessages.runtime);

  return (
    <Wrapper display={display}>
      <MovieCard
        movie={movie}
        formattedMovieRuntime={
          movie?.runtime
            ? formatDuration(movie.runtime, durationFormat)
            : undefined
        }
        theater={theater}
        schedule={schedule}
        groupType={groupType}
        groupBy={groupBy}
        display={display}
      />
    </Wrapper>
  );
};

export default memo(MovieScheduleComponent);

/** @jsx jsx */
import { MovieDetails, responsive } from "@boxoffice/screenplay";
import { Props } from "gatsby-theme-showtimes-grid/src/components/ShowtimesGrid/Grid/MovieSchedule/Card";
import messages from "gatsby-theme-showtimes-grid/src/components/ShowtimesGrid/Grid/MovieSchedule/Card/i18n";
import React, { memo } from "react";
import Link from "shared/components/Link";
import MoviePoster from "shared/components/MoviePoster";
import PlayButton from "shared/components/PlayButton";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { loadable } from "shared/helpers/loadableComponent";
import useAnalytics from "shared/hooks/useAnalytics";
import useMovieTrailer from "shared/hooks/useMovieTrailer";
import { Grid, jsx } from "theme-ui";

const Schedule = loadable(() => import("gatsby-theme-showtimes-grid"), {
  resolveComponent: (components) => components.Schedule,
});

const MovieScheduleCard: React.FC<Props> = ({
  movie,
  theater,
  schedule,
  formattedMovieRuntime,
  groupType,
  groupBy,
}) => {
  const { formatMessage } = useIntl();
  const analytics = useAnalytics();
  const [MovieTrailer, launchMovieTrailer, hasTrailer] = useMovieTrailer(movie);

  return (
    <div
      sx={{
        paddingTop: responsive({ xs: 4, sm: 5 }),
        paddingRight: responsive({ xs: 0, sm: 5 }),
        paddingBottom: responsive({ xs: 5 }),
        paddingLeft: responsive({ xs: 0, sm: 5 }),
      }}
    >
      <Grid
        gap={responsive({ xs: 3, sm: 4 })}
        columns={responsive({
          xs: "80px 1fr",
          sm: "120px 1fr",
          md: "150px 1fr",
        })}
        sx={{
          gridTemplateAreas: responsive({
            xs: "'poster details' 'schedule schedule'",
            md: "'poster details' 'poster schedule'",
          }),
        }}
      >
        {/** Poster */}
        <div
          sx={{
            position: "relative",
            gridArea: "poster",
          }}
        >
          <Link to={movie.path} title={movie.title || ""}>
            <MoviePoster
              poster={movie.poster}
              title={movie.title}
              sizes={responsive<number>({
                xs: 80,
                sm: 120,
                md: 150,
                lg: 150,
                xl: 150,
                xxl: 150,
              })}
            />
            {hasTrailer && (
              <div
                onClick={
                  hasTrailer
                    ? (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        launchMovieTrailer();
                        analytics.track("click", {
                          category: "Video",
                          label: "trailer",
                          movieId: movie.id,
                          movieTitle: movie.title || undefined,
                        });
                      }
                    : undefined
                }
                sx={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
              >
                <PlayButton />
              </div>
            )}
          </Link>
        </div>

        {/** Details */}
        <div sx={{ gridArea: "details" }}>
          <MovieDetails
            title={
              <div
                sx={{
                  fontSize: responsive({ xs: 4, md: 5 }),
                  fontWeight: "heading",
                }}
              >
                <Link to={movie.path}>{movie.title || ""}</Link>
              </div>
            }
            certificate={movie.certificate || undefined}
            runtime={formattedMovieRuntime || undefined}
            callToAction={
              <Grid>
                <div>
                  <div>
                    <span sx={{ fontWeight: "bold", marginRight: ".5em" }}>
                      {formatMessage(messages.direction)}
                    </span>
                    {movie.direction}
                  </div>
                  <div>
                    <span sx={{ fontWeight: "bold", marginRight: ".5em" }}>
                      {formatMessage(messages.casting)}
                    </span>
                    {movie.casting}
                  </div>
                  <div>
                    <span sx={{ fontWeight: "bold", marginRight: ".5em" }}>
                      {formatMessage(messages.genre)}
                    </span>
                    {movie.genres}
                  </div>
                </div>
              </Grid>
            }
          />
        </div>

        {theater && schedule && Object.keys(schedule).length > 0 && (
          <div
            sx={{
              borderTopStyle: "solid",
              borderTopColor: (theme) => {
                const bg = getTinyColor(theme.rawColors?.background);
                return bg.isLight()
                  ? bg.shade(15).toHexString()
                  : bg.tint(15).toHexString();
              },
              borderTopWidth: "small",
              gridArea: "schedule",
              paddingTop: 3,
            }}
          >
            <Schedule
              theater={theater}
              movie={movie}
              schedule={schedule}
              groupType={groupType}
              groupBy={groupBy}
            />
          </div>
        )}
      </Grid>
      {MovieTrailer}
    </div>
  );
};

export default memo(MovieScheduleCard);

/** @jsx jsx */
import { Button } from "@boxoffice/screenplay";
import type { MovieCollection } from "gatsby-theme-schedule-options-bar";
import {
  useScheduleContext,
  useShowtimesDates,
} from "gatsby-theme-schedule-options-bar";
import { DateTime } from "luxon";
import React, { memo } from "react";
import { FALLBACK_TIME_ZONE } from "shared/constants";
import { timeString } from "shared/helpers/date/getReleaseWeekFromDateTime";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx, Themed } from "theme-ui";

import type { Movie, Theater } from "../../types";
import messages, { viewMessages } from "./i18n";

interface Props {
  movies: readonly Movie[];
  theater: Theater;
  view: MovieCollection;
}

const EmptyState: React.FC<Props> = ({ movies, theater, view }) => {
  const pageContext = usePageContext();
  const { formatMessage } = useIntl();

  const { rangeSelection, setRangeSelection } = useScheduleContext();
  const { showtimesDates } = useShowtimesDates({
    theaterId: theater.id,
    theaterTimeZone: theater.timeZone || FALLBACK_TIME_ZONE,
    showtimeWeekReleaseDay: theater.showtimeWeekReleaseDay,
    movieIds: movies.map((movie) => movie.id),
  });

  const nextDateWithShowtimes = showtimesDates.find(
    (date) => date > rangeSelection.range[0]
  );
  const nextDateTime = nextDateWithShowtimes
    ? DateTime.fromISO(nextDateWithShowtimes, {
        zone: "UTC",
      })
    : null;

  return (
    <div sx={{ textAlign: "center" }}>
      {(view === "COMING_SOON" || view === "NOW_PLAYING") && (
        <Themed.p sx={{ fontSize: 3 }}>
          {formatMessage(viewMessages[view])}
        </Themed.p>
      )}
      {view === "NOW_PLAYING" && nextDateTime && (
        <Button
          variant={"secondary"}
          onClick={() => {
            setRangeSelection({
              range: [
                `${nextDateTime.toISODate()}${timeString}`,
                `${nextDateTime.plus({ days: 1 }).toISODate()}${timeString}`,
              ],
              source: "shortcut",
            });
          }}
        >
          {formatMessage(messages.nextShowtimesMessage, {
            date: nextDateTime
              .setLocale(pageContext.intl.locale.long)
              .toLocaleString({
                month: "long",
                day: "numeric",
                weekday: "long",
              }),
          })}
        </Button>
      )}
    </div>
  );
};

export default memo(EmptyState);

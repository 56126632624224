/** @jsx jsx */
import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import useLoyaltyMember from "gatsby-theme-loyalty/src/hooks/useLoyaltyMember";
import { DateTime } from "luxon";
import React, { memo } from "react";
import Link from "shared/components/Link";
import {
  BOOST_TICKETING_DISTINCTIVE_SLUG,
  BOOST_TICKETING_SESSION_REDIRECT_PATH,
  BOOST_TICKETING_SESSION_REDIRECT_URL_PARAM,
} from "shared/constants";
import useIntl from "shared/helpers/i18n/useIntl";
import { MovieShowtime } from "shared/hooks/useAllocineSchedule";
import useAnalytics from "shared/hooks/useAnalytics";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type {
  ShowtimeComponentSettingsQuery,
  TicketingProvider,
} from "../../../../../../../../__generated__/gatsby.types";
import type { Movie, Theater } from "../../../../../types";
import ShowtimeComponent from "./Component";
import messages from "./i18n";

interface Props {
  movie?: Movie;
  showtime?: MovieShowtime | null;
  theater: Theater;
  ticketingProvider?: TicketingProvider | null;
}

type Ticketing = Exclude<MovieShowtime["data"], null | undefined>["ticketing"];

const getLink = (
  provider: TicketingProvider | null | undefined,
  ticketing: Ticketing
): string | null => {
  return (
    ticketing?.find((ticketing) => ticketing?.provider === provider)
      ?.urls?.[0] || null
  );
};

const getLoyaltyLink = (
  prevLink: string,
  encryptedSessionId: string
): string => {
  // requestData only works on redirect path
  const link = new URL(
    prevLink.replace(
      BOOST_TICKETING_DISTINCTIVE_SLUG,
      BOOST_TICKETING_SESSION_REDIRECT_PATH
    )
  );
  link.searchParams.append(
    BOOST_TICKETING_SESSION_REDIRECT_URL_PARAM,
    encryptedSessionId
  );
  return link.href;
};

const Showtime: React.FC<Props> = ({ movie, showtime, theater }) => {
  const pageContext = usePageContext();
  const analytics = useAnalytics();
  const { formatMessage } = useIntl();
  const [member] = useLoyaltyMember({ useCachedMember: true });
  const encryptedSessionId = member?.encryptedSessionId;

  const data = useStaticQuery<ShowtimeComponentSettingsQuery>(graphql`
    query ShowtimeComponentSettings {
      allTheater {
        nodes {
          id
          ticketingProvider
        }
      }
    }
  `);

  if (!movie || !showtime) {
    return null;
  }

  const defaultLink = getLink("default", showtime.data?.ticketing);

  const theaterTicketingProvider: TicketingProvider | null | undefined =
    data.allTheater?.nodes?.find(
      (node) => node.id === theater.id
    )?.ticketingProvider;

  const forcedProviderLink = getLink(
    theaterTicketingProvider,
    showtime.data?.ticketing
  );

  let link =
    forcedProviderLink || (!theaterTicketingProvider ? null : defaultLink);

  // Boost ticketing links contain "startticketing" - hopefully no other POS links do
  if (link?.includes(BOOST_TICKETING_DISTINCTIVE_SLUG) && encryptedSessionId) {
    link = getLoyaltyLink(link, encryptedSessionId);
  }

  const expired = showtime.isExpired;
  const hasLink = !!link;

  const startsAtIntl =
    (showtime?.startsAt
      ? DateTime.fromISO(showtime.startsAt, {
          zone: "UTC",
        })
          .setLocale(pageContext.intl.locale.long)
          .toLocaleString(DateTime.TIME_SIMPLE)
      : null) || "—";

  let audioVersion = "";

  switch (showtime.diffusionVersion) {
    case "ORIGINAL":
      audioVersion = formatMessage(messages.ORIGINAL);
      break;
  }

  const Content = (
    <div>
      <time
        sx={{
          fontSize: 2,
          fontWeight: "bold",
        }}
      >
        {startsAtIntl}
      </time>
      {audioVersion && (
        <div sx={{ fontSize: 0, textAlign: "center" }}>
          <div>{audioVersion}</div>
        </div>
      )}
    </div>
  );

  return (
    <ShowtimeComponent
      key={showtime.id}
      as={Link}
      to={link || undefined}
      isExpired={expired}
      hasLink={!!link}
      className={classnames({
        expired: expired,
        clickable: hasLink,
      })}
      onClick={() => {
        analytics.track("click", {
          category: "Schedule",
          label: showtime.startsAt,
          movieTitle: movie.title || undefined,
          movieId: movie.id || undefined,
          theaterId: theater?.id,
          theaterName: theater?.name || undefined,
        });
      }}
      startsAt={Content}
    />
  );
};

export default memo(Showtime);

import { ThemeUIStyleObject } from "theme-ui";

const showtimesGroup: ThemeUIStyleObject = {
  marginBottom: 3,
  "&:last-of-type": {
    marginBottom: 0,
  },
};

const sxStyles: Record<"showtimesGroup", ThemeUIStyleObject> = {
  showtimesGroup,
};

export default sxStyles;

import type { MovieCollection } from "gatsby-theme-schedule-options-bar";
import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  nextShowtimesMessage: {
    id: "showtimes-grid.next-showtimes-message",
    defaultMessage: "Next showtimes on {date}",
  },
});

export const viewMessages = defineMessages<
  Extract<MovieCollection, "NOW_PLAYING" | "COMING_SOON">
>({
  NOW_PLAYING: {
    id: "showtimes-grid.empty-state-now-playing-message",
    defaultMessage: "There are no showtimes on the selected time period",
  },
  COMING_SOON: {
    id: "showtimes-grid.empty-state-coming-soon-message",
    defaultMessage: "There are no movies coming soon",
  },
});

export default messages;

import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  DUBBED: {
    id: "schedule.movie.showtimes.showtime.audio-version.dubbed-label",
    defaultMessage: "Dubbed",
  },
  ORIGINAL: {
    id: "schedule.movie.showtimes.showtime.audio-version.original-label",
    defaultMessage: "OV",
  },
});

export default messages;

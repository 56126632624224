/** @jsx jsx */
import { ShowtimeListing } from "@boxoffice/screenplay";
import { Attributes } from "gatsby-theme-attribute";
import groupArrayBy from "lodash/groupBy";
import React, { Fragment, memo } from "react";
import { getVersion, ShowtimeTags } from "shared/helpers/movie/version";
import { MovieShowtime } from "shared/hooks/useAllocineSchedule";
import { jsx } from "theme-ui";

import type {
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../../../../../__generated__/gatsby.types";
import type { Movie, Theater } from "../../../../types";
import Showtime from "./Showtime";
import sxStyles from "./styles";

interface Props {
  movie?: Movie;
  schedule: MovieShowtime[];
  theater: Theater;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
}

const MovieScheduleListingComponent: React.FC<Props> = ({
  movie,
  theater,
  schedule,
  groupType,
  groupBy,
}) => {
  if (!movie) {
    return null;
  }
  switch (groupType) {
    case "SHARED": {
      const grouped = groupArrayBy(schedule, "tags");
      return (
        <Fragment>
          {Object.keys(grouped).map((key) => (
            <div key={`${movie.id}-${key}`} sx={sxStyles.showtimesGroup}>
              <div sx={{ marginBottom: 2 }}>
                <Attributes tags={key.split(",")} size={"small"} />
              </div>
              <ShowtimeListing>
                {grouped[key].map((showtime) => {
                  return (
                    <Showtime
                      key={showtime.id}
                      theater={theater}
                      movie={movie}
                      showtime={showtime}
                    />
                  );
                })}
              </ShowtimeListing>
            </div>
          ))}
        </Fragment>
      );
    }
    case "SINGLE": {
      const grouped = groupArrayBy(schedule, ({ tags, movieLanguage }) => {
        switch (groupBy) {
          case "FORMAT":
            return tags.filter((tag) => tag.includes("Format."))?.join(",");
          case "VERSION":
            return (
              getVersion({
                showtimeTags: tags as ShowtimeTags,
                movieLanguage,
                theaterLanguage: theater.language,
              }) ?? ""
            );
          default:
            return false;
        }
      });
      return (
        <Fragment>
          {Object.keys(grouped).map((key) => {
            const attributes = key.split(",");
            return (
              <div key={`${movie.id}-${key}`} sx={sxStyles.showtimesGroup}>
                <div sx={{ marginBottom: 2 }}>
                  <Attributes tags={attributes} size={"small"} />
                </div>
                <ShowtimeListing>
                  {grouped[key].map((showtime) => {
                    return (
                      <Showtime
                        key={showtime.id}
                        theater={theater}
                        movie={movie}
                        showtime={showtime}
                      />
                    );
                  })}
                </ShowtimeListing>
              </div>
            );
          })}
        </Fragment>
      );
    }
    default:
      return (
        <ShowtimeListing>
          {schedule.map((showtime) => {
            return (
              <Showtime
                key={showtime.id}
                theater={theater}
                movie={movie}
                showtime={showtime}
              />
            );
          })}
        </ShowtimeListing>
      );
  }
};

export default memo(MovieScheduleListingComponent);

import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  schedulePlaceholder: {
    id: "schedule.movie.showtimes.empty-state-message",
    defaultMessage: "No showtimes",
  },
  today: {
    id: "schedule.movie.showtimes.today-label",
    defaultMessage: "Today",
  },
  tomorrow: {
    id: "schedule.movie.showtimes.tomorrow-label",
    defaultMessage: "Tomorrow",
  },
});

export default messages;

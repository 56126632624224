/** @jsx jsx */
import { Heading } from "@boxoffice/screenplay";
import { DateTime } from "luxon";
import React, { Fragment, memo } from "react";
import useIntl, { IntlFormatters } from "shared/helpers/i18n/useIntl";
import { capitalize } from "shared/helpers/string";
import { MovieSchedule } from "shared/hooks/useAllocineSchedule";
import usePageContext from "shared/hooks/usePageContext";
import { Grid, jsx } from "theme-ui";

import type {
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../../../../__generated__/gatsby.types";
import type { Movie, Theater } from "../../../types";
import EmptyState from "../../EmptyState";
import messages from "./i18n";
import Listing from "./Listing";

interface Props {
  movie?: Movie;
  schedule?: MovieSchedule | null;
  theater: Theater;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
}

type Messages = typeof messages;

const formatDate = ({
  date,
  locale,
  formatMessage,
  messages,
  timeZone,
}: {
  timeZone?: string;
  date: string;
  locale: string;
  formatMessage: IntlFormatters["formatMessage"];
  messages: Messages;
}): string => {
  // This gives us the "now" of the theater
  const now = DateTime.local().setZone(timeZone || "UTC");

  const dateObj = DateTime.fromISO(date, {
    zone: timeZone || "UTC",
  });

  switch (dateObj.diff(now.startOf("day"), "days").days) {
    case 0:
      return formatMessage(messages.today);
    case 1:
      return formatMessage(messages.tomorrow);
    default:
      return capitalize(
        dateObj.setLocale(locale).toLocaleString({
          month: "long",
          day: "numeric",
          weekday: "long",
        })
      );
  }
};

const MovieScheduleComponent: React.FC<Props> = ({
  movie,
  theater,
  schedule,
  groupType,
  groupBy,
}) => {
  const pageContext = usePageContext();
  const { formatMessage } = useIntl();

  if (!movie) {
    return null;
  }

  if (schedule === null) {
    return (
      <EmptyState movies={[movie]} theater={theater} view={"NOW_PLAYING"} />
    );
  }

  if (schedule === undefined) {
    return null;
  }

  const days = Object.keys(schedule || {});

  return (
    <Fragment>
      {days.map((date) => {
        const daySchedule = schedule[date];

        const formattedDate = formatDate({
          date,
          timeZone: theater.timeZone || undefined,
          locale: pageContext.intl.locale.long,
          formatMessage,
          messages,
        });

        return (
          <div
            key={date}
            sx={{
              marginBottom: 4,
              "&:last-of-type": {
                marginBottom: 0,
              },
            }}
          >
            <Grid gap={3}>
              <Heading level={4}>{formattedDate}</Heading>
              <Listing
                movie={movie}
                theater={theater}
                schedule={daySchedule}
                groupType={groupType}
                groupBy={groupBy}
              />
            </Grid>
          </div>
        );
      })}
    </Fragment>
  );
};

export default memo(MovieScheduleComponent);

/** @jsx jsx */
import React, { Fragment, memo } from "react";
import { jsx } from "theme-ui";

interface Props {
  children: React.ReactElement;
}

const ShowtimesGridWrapper: React.FC<Props> = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

export default memo(ShowtimesGridWrapper);

/** @jsx jsx */
import { ShowtimeComponentProps } from "gatsby-theme-showtimes-grid";
import { FC, memo } from "react";
import Link from "shared/components/Link";
import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const ShowtimeComponent: FC<ShowtimeComponentProps> = ({
  startsAt,
  className,
  to,
  onClick,
}) => {
  const { theme } = useThemeUI();

  const buttonBackgroundColor = getMostVisible(theme.rawColors?.background, [
    theme.rawColors?.accent,
  ]).toHexString();

  return (
    <Link
      className={className}
      sx={{
        variant: "buttons.primary",
        width: "100%",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        backgroundColor: buttonBackgroundColor,
        color: getMostReadable(buttonBackgroundColor).toHexString(),
        cursor: "default",
        "&.clickable": {
          cursor: "pointer",
          "&:hover, &:focus, &.active": {
            outline: "none",
            backgroundColor: (theme) =>
              getMostVisible(theme.rawColors?.background, [
                theme.rawColors?.primary,
              ]).toHexString(),
            color: getMostReadable(
              getMostVisible(theme.rawColors?.background, [
                theme.rawColors?.primary,
              ]).toHexString()
            ).toHexString(),
          },
        },
        "&.expired": {
          textDecoration: "line-through",
        },
      }}
      to={to}
      onClick={onClick}
    >
      {startsAt}
    </Link>
  );
};

export default memo(ShowtimeComponent);

/** @jsx jsx */
import React, { memo } from "react";
import { getTinyColor } from "shared/helpers/colors";
import { jsx } from "theme-ui";

interface Props {
  children: React.ReactNode;
}

const MovieScheduleWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div
      sx={{
        borderTopWidth: "small",
        borderTopColor: (theme) => {
          const bg = getTinyColor(theme.rawColors?.background);
          return bg.isLight()
            ? bg.shade(20).toHexString()
            : bg.tint(20).toHexString();
        },
        borderTopStyle: "solid",
      }}
    >
      {children}
    </div>
  );
};

export default memo(MovieScheduleWrapper);

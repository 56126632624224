/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import { RangeKey } from "shared/helpers/ranges";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import type {
  ShowtimesGridQuery,
  WidgetShowtimesDisplay,
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../__generated__/gatsby.types";
import ShowtimesGridComponent from "./Component";
import { Theater } from "./types";

const ScheduleContextProvider = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleContextProvider,
  }
);

interface Props {
  periodFilter?: readonly (RangeKey | null)[] | null;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
  display?: WidgetShowtimesDisplay | null;
  includeDatePicker?: boolean;
  includeDateSlider?: boolean;
  includeComingSoonTab?: boolean;
  includeTheaterSelector?: boolean;
  forcedTheaterId?: string;
}

const ShowtimesGrid: React.FC<Props> = ({
  periodFilter,
  groupType,
  groupBy,
  display: displayProp,
  includeDatePicker,
  includeDateSlider,
  includeComingSoonTab,
  forcedTheaterId,
}) => {
  const data = useStaticQuery<ShowtimesGridQuery>(graphql`
    query ShowtimesGrid {
      allTheater(sort: { fields: name, order: ASC }) {
        nodes {
          id
          timeZone
          showtimeWeekReleaseDay
          name
          language
        }
      }
      allMovie(
        filter: {
          collections: {
            in: [NOW_PLAYING, NEXT_PLAYING, COMING_SOON, FUTURE_PLAYING]
          }
        }
      ) {
        nodes {
          id
          title
          path
          certificate
          poster
          trailer {
            SD
            HD
          }
          release
          runtime
          genres
          direction
          casting
          collections
          theaters {
            theater {
              id
            }
            isNew
          }
        }
      }
      comingSoon: allMovie(filter: { collections: { eq: COMING_SOON } }) {
        nodes {
          id
          title
          path
          certificate
          poster
          trailer {
            SD
            HD
          }
          release
          runtime
          genres
          direction
          casting
          theaters {
            theater {
              id
            }
          }
        }
      }
    }
  `);

  const [storedTheaterId] = useSelectedTheaterId();
  const selectedTheaterId = forcedTheaterId || storedTheaterId;
  const pageContext = usePageContext();

  const selectedTheater: Theater | null = pageContext.isSingleLocation
    ? data.allTheater.nodes[0]
    : data.allTheater.nodes.find(
        (theater) => theater.id === selectedTheaterId
      ) || null;

  let display = displayProp;
  if (!display) {
    display =
      pageContext.templateName === "mcdaniel" ? "TWO_COLUMNS" : "MOVIE_PER_ROW";
  }

  return (
    <ScheduleContextProvider
      showtimeWeekReleaseDay={selectedTheater?.showtimeWeekReleaseDay}
      timeZone={selectedTheater?.timeZone}
      defaultRange={
        periodFilter?.filter((period) => period !== "INDIVIDUAL_DAYS")?.[0]
      }
    >
      <ShowtimesGridComponent
        data={data}
        periodFilter={periodFilter}
        groupType={groupType}
        groupBy={groupBy}
        display={display}
        includeDatePicker={includeDatePicker}
        includeDateSlider={includeDateSlider}
        includeComingSoonTab={includeComingSoonTab}
        includeTheaterSelector={!forcedTheaterId}
        selectedTheater={selectedTheater}
      />
    </ScheduleContextProvider>
  );
};

export default memo(ShowtimesGrid);

/** @jsx jsx */
import type { MovieCollection } from "gatsby-theme-schedule-options-bar";
import React, { memo, useEffect, useRef } from "react";
import { Schedule } from "shared/hooks/useAllocineSchedule";
import { jsx } from "theme-ui";

import type {
  WidgetShowtimesDisplay,
  WidgetShowtimesGroupBy,
  WidgetShowtimesGroupType,
} from "../../../../__generated__/gatsby.types";
import type { Movie, Theater } from "../types";
import EmptyState from "./EmptyState";
import MovieSchedule from "./MovieSchedule";
import Wrapper from "./Wrapper";

type Movies = readonly Movie[];

interface Props {
  view: MovieCollection;
  movies: Movies;
  schedule: Schedule | undefined;
  theater?: Theater;
  groupType?: WidgetShowtimesGroupType | null;
  groupBy?: WidgetShowtimesGroupBy | null;
  display?: WidgetShowtimesDisplay;
  loading?: boolean;
}

const ShowtimesGridComponent: React.FC<Props> = ({
  view,
  movies,
  schedule,
  theater,
  groupType,
  groupBy,
  display,
  loading,
}) => {
  const prevMovies = useRef<Movies>(movies);
  const prevSchedule = useRef<Schedule | undefined>(schedule);

  useEffect(() => {
    if (movies) {
      prevMovies.current = movies;
    }
  }, [movies]);

  useEffect(() => {
    if (schedule) {
      prevSchedule.current = schedule;
    }
  }, [schedule]);

  if (!loading && theater && movies.length === 0) {
    return <EmptyState movies={movies} theater={theater} view={view} />;
  }

  return (
    <Wrapper display={display}>
      {(loading ? prevMovies.current : movies).map((movie) => {
        const correspondingSchedule =
          view === "COMING_SOON"
            ? undefined
            : (loading ? prevSchedule.current : schedule)?.[movie.id] || null;

        return (
          <MovieSchedule
            key={movie.id}
            movie={movie}
            theater={theater}
            schedule={correspondingSchedule}
            groupType={groupType}
            groupBy={groupBy}
            display={display}
          />
        );
      })}
    </Wrapper>
  );
};

export default memo(ShowtimesGridComponent);
